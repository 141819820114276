<template>
  <div class="payment-type payment-type-delivery">
    <i18n
      :path="
        `payment.paymentTypeList.tooltip.${paymentType.paymentTypeId}.${getDeliveryServiceId}`
      "
      tag="span"
      class="tooltip-payment-methods"
    />

    <v-alert
      v-if="error"
      type="error"
      border="left"
      transition="v-fab-transition"
    >
      {{ error }}
    </v-alert>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end mt-5">
      <v-col cols="6" md="6" class="d-flex" v-if="mode != 'order'">
        <v-btn
          outlined
          color="primary"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          class="bg-white"
        >
          {{ $t("common.back") }}
        </v-btn>
      </v-col>
      <v-col cols="6" md="6" class="d-flex justify-end">
        <v-btn
          color="primary"
          class="ml-2 gateway-btn"
          v-on:click="buy"
          depressed
          :disabled="paymentDisabled"
        >
          {{ $t("payment.paymentTypeList.orderConfirm") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import { mapActions, mapGetters } from "vuex";
var vm;

export default {
  name: "PaymentTypeDelivery",
  mixins: [gateway],
  props: {
    paymentDisabled: { type: Boolean, default: false }
  },
  components: { ResponseMessage },
  data() {
    return { loading: false, error: null, response: {} };
  },
  computed: {
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    })
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      // UPDATE CART INFO AND PAY
      vm.loading = true;
      try {
        if (vm.mode == "cart") {
          let cart = await vm.buyCart(vm.paymentType);
          if (cart) {
            let orderId = cart.order ? cart.order.orderId : cart.orderId;
            global.EventBus.$emit("pay-response-ok", cart);
            this.$router.push("/cart/download-request-completed/" + orderId);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId
          );
          if (response) {
            global.EventBus.$emit("pay-response-ok", response);
            this.$router.push("/cart/download-request-completed/" + vm.orderId);
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    vm = this;
  }
};
</script>
